"use client";
import React from "react";

//CSS:
import "../../../assets/styles/css/fonts.css";

//Material UI:
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    MuiFormControlLabel: {
        styleOverrides: {
          asterisk: {
            display: 'none',
          },
        },
    },
    palette: {
        mode: 'light',
        primary: {
          main: '#590c89',
          light: '#7a3d9e',
          dark: '#3c085c',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#a361ff',
          light: '#b381fb',
          dark: '#7042af',
          contrastText: '#ffffff',
        },
        text: {
          primary: 'rgba(15,15,16,0.87)',
          secondary: 'rgba(41,41,45,0.87)',
          disabled: 'rgba(187,187,187,0.38)',
        },
        success: {
          main: '#1f9826',
          light: '#51b357',
          dark: '#196f1e',
          contrastText: '#ffffff',
        },
        divider: 'rgba(183,182,182,0.12)',
        error: {
          main: '#d22a2a',
          light: '#d05252',
          dark: '#9a2a2a',
          contrastText: '#ffffff',
        },
        warning: {
          main: '#ef6d03',
          light: '#f78d37',
          dark: '#ad4f03',
          contrastText: '#ffffff',
        },
        info: {
          main: '#0488d0',
          light: '#329bd4',
          dark: '#03659a',
          contrastText: '#ffffff',
        },
      },
      typography: {       
        h1: {
          fontFamily: 'Poppins',
          fontSize: 60,
          fontWeight: 900,
          lineHeight: 1,
        },
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontWeightExtraBold: 900,
        htmlFontSize: 16,
        h2: {
          fontSize: 45,
          fontWeight: 600,
          lineHeight: 1.21,
        },
        h3: {
          fontSize: 35,
          fontWeight: 600,
          lineHeight: 1,
        },
        h4: {
          fontSize: 30,
          fontWeight: 600,
          lineHeight: 1,
        },
        h5: {
          fontWeight: 600,
          fontSize: 25,
          lineHeight: 1,
        },
        h6: {
          fontWeight: 600,
          fontSize: 20,
          lineHeight: 1,
        },
        subtitle1: {
          fontSize: 17,
          fontWeight: 400,
          lineHeight: 1.76,
        },
        subtitle2: {
          fontSize: 15,
          fontWeight: 500,
          lineHeight: 1.51,
        },
        body1: {
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 1.58,
        },
        body2: {
            fontFamily: 'Poppins',
            fontSize: 15,
            lineHeight: 1,
            fontWeight: 400,
        },
        button: {
          fontWeight: 500,
          fontSize: '0.8rem',
          lineHeight: 1.78,
        },
        caption: {
          fontSize: '0.8rem',
          fontWeight: 400,
          lineHeight: 1.7,
        },
        overline: {
          fontSize: '0.9rem',
          fontWeight: 500,
        },
      },
      spacing: 8, 
      shape: {
        borderRadius: 4,
      },
      components: {
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: 46,
              height: 27,
              padding: 0,
              margin: 8,
            },
            switchBase: {
              padding: 1,
              '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + $track': {
                  opacity: 1,
                  border: 'none',
                },
              },
            },
            thumb: {
              width: 24,
              height: 24,
            },
            track: {
              borderRadius: 13,
              border: '1px solid #bdbdbd',
              backgroundColor: '#fafafa',
              opacity: 1,
              transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
          },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                border: 0,
                borderRadius: 50,
                height: 35,
                padding: '0 30px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
              root: {
                textAlign: 'left',
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DBD8DC",
                    borderWidth: "1px",
                },
                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#612cab",
                        borderWidth: "2px",
                    },
                },
                "&:hover:not(.Mui-focused)": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DBD8DC",
                    },
                },         
              },
            },
        },    
        MuiCard: {
            styleOverrides: {
              root: {
                padding: '15px auto 20px',
                margin: '0 0 25px',
                borderRadius: 10,
                border: '1px solid #e0e0e0',
                background: 'no-repeat 0% 0% #fbf9ff padding-box',
                boxShadow: 'none',
                },
            },
        },  
        MuiMenuItem: {
            styleOverrides: {
                root: {
                "&.Mui-selected": {
                    backgroundColor: "#000"
                }
                }
            }
        },
      },
      props: {
        MuiButton: {
          size: 'small',
        },
        MuiButtonGroup: {
          size: 'small',
        },
        MuiCheckbox: {
          size: 'small',
        },
        MuiFab: {
          size: 'small',
        },
        MuiFormControl: {
          margin: 'dense',
          size: 'small',
        },
        MuiFormHelperText: {
          margin: 'dense',
        },
        MuiIconButton: {
          size: 'small',
        },
        MuiInputBase: {
          margin: 'dense',
        },
        MuiInputLabel: {
          margin: 'dense',  
        },
        MuiRadio: {
          size: 'small',
        },
        MuiSwitch: {
          size: 'small',
        },
        MuiTextField: {
          margin: 'dense',
          size: 'small',
        },
        MuiTooltip: {
          arrow: true,
        },
        MuiList: {
          dense: true,
        },
        MuiMenuItem: {
          dense: true,
        },
        MuiTable: {
          size: 'small',
        }, 
      },
});

const ThemeMui = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeMui;
