import LoadingButton from "@mui/lab/LoadingButton"; 
import MenuItem from "@mui/material/MenuItem"; 
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"; 
import { municipalitiesAction } from "../../../_models/redux/municipalities/action";
import { neighborhoodsAction } from "../../../_models/redux/neighborhoods/action";
import { shippinghAddressAction } from "../../../_models/redux/shipping-address/action";
import { cleanShippinghAddressAction } from "../../../_models/redux/shipping-address/clean";
import getStaticStates from "./widgets/states";
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";
// import { clarity } from "react-microsoft-clarity";

//Material UI:
import { Container, Grid, Typography, Button, TextField, Box } from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'; 

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

//Styles MUI:
import {
    containerFormLogin, 
    gridContainerLogin,
    titleContainer,
    iconArrowBack,
    typographyTitleOne,
    typographyTitleTwo, 
    linkContainerForgotPassword,
    linkForgotPassword,
} from "./styles-mui/shipAddressPurchasePageStylesMUI";

const AddressPurchasePage = (props) => {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );

        FacebookPixel.pageView();

        /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        /*** ***/
        /*** ***/
    }, []);

    let navigate = useNavigate();
    const { state } = useLocation();
    const { tokenView, elementSelect, supportsDevices } = state || {};
    const { states } = getStaticStates();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { municipalitiesData, neighborhoodsData, shippingAddress } =
        props || {};
    const { shippingAddressObj } = shippingAddress;
    const { response } = shippingAddressObj || "undefined";
    const { status } = response || "undefined";
    let shippingView = shippingAddressObj.data;
    const { message } = shippingView || "undefined";

    const { neighborhoodsObj } = neighborhoodsData;
    let objNeghborhoods = neighborhoodsObj.data;
    const { neighborhoods } = objNeghborhoods || "undefined";
    const { municipalitiesObj } = municipalitiesData || "undefined";
    const { data } = municipalitiesObj || "undefined";
    const { municipalities } = data || "undefined";

    const [neighborhood_id, createNeighborhood_id] = useState([]);
    const [statesSelects, createStatesSelects] = useState();
    const [municipalitiesValue, createMunicipalitiesValue] = useState();
    const [address, createAddress] = useState("");
    const [contact_phone, createContact_phone] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const [responseErrors, setResponseErrors] = useState();
    const [reference, setReference] = useState("");

    const registerOptions = {
        statesSelects: { required: "Debe seleccionar un Estado " },
        municipalitiesValue: { required: "Debe seleccionar un Municipio" },
        neighborhood_id: { required: "Debe seleccionar una Colonia" },
        address: {
            required: "Debe ingresar su calle y número",
            maxLength: 250,
            minLength: 3,
        },
        contact_phone: {
            required: "Número telefónico es requerido",
            maxLength: 12,
            minLength: 10,
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
            },
        },
        reference: {
            minLength: { value: 10 },
            required: "Este campo es requerido",
        },
    };

    if (message === "success") {
        /*** GOOGLE pixel  ***/
        // Track the "add_shipping_info" event
        ReactGA.event("ecommerce", "add_shipping_info", {
            currency: "MXN",
            value: 0.0, // total_amount
            items: 1,
        });
        /*** ***/

        /*** TIKTOK pixel  ***/
        let ttParams = {};
        let ttTrackingOpt = {
            // event_id: "1234", //OPTIONAL
        };
        TiktokPixel.track("SubmitForm", ttParams, ttTrackingOpt);
        /*** ***/

        /*** FACEBOOK pixel  ***/
        let fbParams = {};
        FacebookPixel.track("SubmitApplication", fbParams);
        /*** ***/

        navigate(`/purchase/payment-method`, {
            state: { tokenView, elementSelect, supportsDevices },
        });
    }

    useEffect(() => {
        if (tokenView === undefined) window.location.replace("/purchase");

        return () => {
            props.cleanShippinghAddressAction();
        };
    }, []);

    useEffect(() => {
        if (typeof statesSelects != "undefined") {
            props.municipalitiesAction(statesSelects);
        }
        if (typeof municipalitiesValue != "undefined") {
            props.neighborhoodsAction(municipalitiesValue);
        }
    }, [statesSelects, municipalitiesValue]);

    useEffect(() => {
        if (shippingAddress?.shippingAddressObj?.response?.status == 401) {
            navigate(`/purchase`);
        }

        const { loading, shippingAddressObj } = shippingAddress || {};

        if (!loading) {
            const { name, response } = shippingAddressObj || {};

            if (name == "AxiosError" && typeof response != "undefined") {
                const { data } = response || {};
                const { additional_details } = data || {};
                const { errors } = additional_details || {};

                if (typeof errors != "undefined") {
                    setResponseErrors(errors);
                }
            }
        }

        if (status >= 400) {
            setBtnLoading(false);
        }
    }, [shippingAddress]);

    const onSubmit = (e) => {
        setBtnLoading(true);
        const post = {
            address: address,
            neighborhood_id: neighborhood_id,
            contact_phone: contact_phone,
            tokenView: tokenView,
            reference: reference,
        };
        props.shippinghAddressAction(post);
    };

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainerLogin}>   
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={7} sx={titleContainer}>                     
                        <a href="/client">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>
                            Dirección</Typography>
                        <Typography sx={typographyTitleTwo}>
                            De envío
                        </Typography>  
                    </Grid>
                    <Grid item xs={12} md={5} sx={containerFormLogin}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                                

                                <Grid item xs={12} mt={{xs: 3.7, md: 2}}>                                   
                                    <TextField
                                        select
                                        fullWidth
                                        {...register(
                                            "statesSelects",
                                            registerOptions.statesSelects
                                        )}
                                        label="Estado"
                                        variant="outlined" 
                                        onChange={(event) => {
                                            createStatesSelects(
                                                event.target.value
                                            );
                                        }}
                                        defaultValue=""                                        
                                        sx={{
                                            width: "100%", 
                                            textAlign: "left",
                                            margin: "0 0 15px", 
                                            "& .MuiOutlinedInput-root": {                                                 
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            }
                                        }}
                                    >
                                        {states?.map((option, index) => (
                                            <MenuItem
                                                key={index || 0}
                                                value={option.id || 0}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <span>
                                        {errors?.statesSelects && (
                                            <p className="required-incidence">
                                                {errors.statesSelects.message}
                                            </p>
                                        )}
                                    </span>

                                    <TextField
                                        select
                                        fullWidth
                                        {...register(
                                            "municipalitiesValue",
                                            registerOptions.municipalitiesValue
                                        )}
                                        id="outlined-basic"
                                        label="Municipio"
                                        variant="outlined" 
                                        defaultValue=""
                                        onChange={(event) => {
                                            createMunicipalitiesValue(
                                                event.target.value
                                            );
                                        }}
                                        sx={{
                                            width: "100%", 
                                            margin: "0 0 15px", 
                                            textAlign: "left",
                                            "& .MuiOutlinedInput-root": {                                                 
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            }
                                        }}
                                    >
                                        {municipalities?.map(
                                            (option, index) => (
                                                <MenuItem
                                                    key={index || 0}
                                                    value={option.id || 0}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                    <span>
                                        {errors?.municipalitiesValue && (
                                            <p className="required-incidence">
                                                {
                                                    errors.municipalitiesValue
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </span>

                                    <TextField
                                        select
                                        fullWidth
                                        defaultValue=""
                                        {...register(
                                            "neighborhood_id",
                                            registerOptions.neighborhood_id
                                        )}
                                        label="Colonia"
                                        variant="outlined" 
                                        // value={neighborhood_id}
                                        onChange={(event) => {
                                            createNeighborhood_id(
                                                event.target.value
                                            );
                                        }}
                                        sx={{
                                            width: "100%", 
                                            margin: "0 0 15px", 
                                            textAlign: "left",
                                            "& .MuiOutlinedInput-root": {                                                 
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            }
                                        }}
                                    >
                                        {neighborhoods?.map((option, index) => (
                                            <MenuItem
                                                key={index || 0}
                                                value={option?.id || ""}
                                            >
                                                {option?.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <span>
                                        {errors?.neighborhood_id && (
                                            <p className="required-incidence">
                                                {errors.neighborhood_id.message}
                                            </p>
                                        )}
                                    </span>

                                    <TextField
                                        {...register(
                                            "address",
                                            registerOptions.address
                                        )}
                                        fullWidth
                                        id="outlined-basic"
                                        label="Calle y número int/ext"
                                        variant="outlined" 
                                        value={address}
                                        onChange={(event) => {
                                            createAddress(event.target.value);
                                        }}
                                        sx={{
                                            width: "100%", 
                                            margin: "0 0 15px", 
                                            "& .MuiOutlinedInput-root": {                                                 
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            }
                                        }}
                                    />
                                    <span>
                                        {errors?.address && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.address.message}
                                            </p>
                                        )}
                                    </span>
                                    <span>
                                        {errors?.address &&
                                            ["minLength", "maxLength"].indexOf(
                                                errors?.address.type
                                            ) !== -1 && (
                                                <p className="required-incidence">
                                                    Mínimo 3 caracteres, máximo
                                                    350 caracteres
                                                </p>
                                            )}
                                    </span>

                                    <TextField
                                        {...register(
                                            "reference",
                                            registerOptions.reference
                                        )}
                                        fullWidth
                                        id="outlined-basic"
                                        label="Referencias de domicilio"
                                        variant="outlined" 
                                        value={reference}
                                        onChange={(event) => {
                                            setReference(event.target.value);
                                        }}
                                        sx={{
                                            width: "100%", 
                                            margin: "0 0 15px", 
                                            "& .MuiOutlinedInput-root": {                                                 
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            }
                                        }}
                                    />
                                    <span>
                                        {errors?.reference && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.reference.message}
                                            </p>
                                        )}
                                    </span>
                                    <span>
                                        {errors?.reference &&
                                            ["minLength", "maxLength"].indexOf(
                                                errors?.reference.type
                                            ) !== -1 && (
                                                <p className="required-incidence">
                                                    Mínimo 10 caracteres
                                                </p>
                                            )}
                                    </span>

                                    <TextField
                                        {...register(
                                            "contact_phone",
                                            registerOptions.contact_phone
                                        )}
                                        fullWidth
                                        label="Número telefónico"
                                        variant="outlined" 
                                        value={contact_phone}
                                        onChange={(event) => {
                                            createContact_phone(
                                                event.target.value
                                            );
                                        }}
                                        sx={{
                                            width: "100%", 
                                            margin: "0 0 10px", 
                                            "& .MuiOutlinedInput-root": {                                                 
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            }
                                        }}
                                    />
                                    <span>
                                        {errors?.contact_phone && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.contact_phone.message}
                                            </p>
                                        )}
                                    </span>
                                    <span>
                                        {errors?.contact_phone &&
                                            ["minLength", "maxLength"].indexOf(
                                                errors?.contact_phone.type
                                            ) !== -1 && (
                                                <p className="required-incidence">
                                                    Mínimo 10 dígitos, máximo 12
                                                    dígitos
                                                </p>
                                            )}
                                    </span>
                                    <span>
                                        {errors?.contact_phone &&
                                            errors?.contact_phone.type ==
                                                "pattern" && (
                                                <p className="required-incidence">
                                                    Tiene algún dígito
                                                    incorrecto
                                                </p>
                                            )}
                                    </span>
                                </Grid>

                                <Grid
                                    container
                                    className="required-incidence error-response-container"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className="required-incidence error-response"
                                    >
                                        {response?.data?.detail}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    className="required-incidence error-response-container"
                                >
                                    {typeof responseErrors != "undefined"
                                        ? responseErrors.map(
                                              (reElement, reIndex) => {
                                                  return typeof reElement.message !=
                                                      "undefined" ? (
                                                      <Grid
                                                          key={reIndex}
                                                          item
                                                          xs={12}
                                                          className="required-incidence error-response"
                                                      >
                                                          {reElement.message}
                                                      </Grid>
                                                  ) : (
                                                      ""
                                                  );
                                              }
                                          )
                                        : null}
                                </Grid>


                                <Box mb={2.9}>
                                    <CustomButtonComponent
                                        async={true}
                                        loading={btnLoading}
                                        bgColor={"primary-purple"}
                                        text={"Enviar a esta dirección"}
                                        height={55}
                                    />
                                </Box>                                    
                            </form>
                    </Grid>
                </Grid>            
            </Container>
        </BaseFirstStepsComponent>

                            
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    municipalitiesData: state.municipalitiesData,
    shippingAddress: state.shippingAddress,
    neighborhoodsData: state.neighborhoodsData,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    shippinghAddressAction,
    municipalitiesAction,
    neighborhoodsAction,
    cleanShippinghAddressAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressPurchasePage);
