export const gridContainer = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
};

export const titleContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const typographyTitleOne = {
    fontSize: { xs: 36, md: 55 },
    color: "white",
    lineHeight: 1.2,
    fontFamily: "Poppins",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 40, md: 69 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins",
    fontWeight: 700,
    textAlign: "start",
};

export const formContainer = {
    my: { xs: 3, md: 2 },
    backgroundColor: "white",
    borderRadius: 7,
    pt: { xs: 3, md: 4 },
    pb: { xs: 1, md: 1 },
    px: { xs: 3, md: 6 },
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
};

export const iconArrowBack = {
    fontSize: 32,
    color: "white",
    mb: { xs: 1, md: 1 },
    mt: 0,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};

export const formControlLabel = {
    textAlign: "start",
    display: "flex",
    alignItems: "start",
    ".MuiFormControlLabel-asterisk": {
        display: "none",
    },
};

export const checkboxComponent = {
    fontSize: 14,
    my: 1,
};

export const buttonComponent = {
    mt: 2,
    mb: 2,
};

export const linkComponent = {
    lineHeight: 1,
    fontWeight: "bold",
    textTransform: "none",
    textDecoration: "none",
    color: "#7961a5",
    px: 0.1,
    "&:hover": {
        backgroundColor: "transparent",
        color: "#673ab7",
    },
};
