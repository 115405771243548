export const gridContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    height: "100vh",
};

export const logoContainer = {
    mb: 6,
    display: { xs: "none", md: "block" },
};

export const titleContent = {
    alignItems: { xs: "center", md: "start" },
    mb: 3,
    display: { xs: "none", md: "block" },
};

export const typographyTitleOne = {
    fontSize: { xs: 40, sm: 50, md: 60 },
    fontWeight: 400,
    color: "white",
    lineHeight: 1.2,
    mt: 0,
};

export const typographyTitleTwo = {
    fontSize: { xs: 50, sm: 65, md: 80 },
    color: "white",
    lineHeight: 1.1,
    fontFamily: "Poppins",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleThree = {
    fontSize: { xs: 49, sm: 60, md: 80 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins",
    fontWeight: 700,
};

export const typographyDescriptionTitle = {
    mt: { md: 0 },
    fontSize: { md: 25 },
    mb: { md: 4 },
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 700,
    display: { xs: "none", md: "block" },
};

export const typographyDescription = {
    mt: { xs: 3, md: 0 },
    fontSize: 17,
    mb: 5,
    textAlign: "center",
    lineHeight: { xs: 1.7, md: 1.6 },
    display: { xs: "none", md: "block" },
};

export const gridItemCard = {
    mt: { xs: 59, sm: 75, md: 0 },
    textAlign: {
        xs: "center",
        md: "start",
    },
    px: { xs: 2, md: 7 },
    py: { xs: 2, md: 5 },
    display: "flex",
    justifyContent: "center",
    borderRadius: 6,
    background: { xs: "transparent", md: "white" },
    color: { xs: "white", md: "#1e1e1e" },
    boxShadow: { xs: "none", md: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px" },
};
